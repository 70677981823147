import React, { useState, useEffect } from 'react'
import { PageTemplate } from '../templates/page'
import { destroy, get, list, quantity, total } from 'cart-localstorage'
import { navigate } from 'gatsby'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { roundAndFix, addVatTo, removeVatFrom } from '../scripts/helpers'
import { loadStripe } from '@stripe/stripe-js'
import SiteMetadata from '../components/SiteMetadata'
import Button from '../components/shortcodes/Button'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: "Order Details",
  pageIdentifier: "checkout",
  pageClass: "hide-cart footer-dimmed",
  showCta: false,
  showContactForm: false,
}

const PaymentPage = () => {
  // Prevent Gatsby from prerendering this page
  // Define states
  const [cart, setCart] = useState(typeof window !== 'undefined' ? list() : null)
  
  if (typeof window == 'undefined') {
    return false
  }

  // Build page if possible
  if (cart && cart.length) {
    let spread = {}
    let isNotShippable = false

    // Convert cart into a cart spread
    cart.forEach(item => {
      if (!item.intlAvailable) {
        isNotShippable = true
      }
    })

    /**
     * Helper function to throw error via Toastify
     * 
     * @param {string}  message
     * @param {string}  [element] // Scroll to this element
     */
    function throwPopupError(message, element) {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })

      if (element) {
        element.scrollIntoView()
        element.focus()
      }
    }

    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
        notNarrow={true}
      >
        <div class="notification is-danger has-text-centered">
          <strong>Important Note: All orders and deliveries are paused currently.</strong>
        </div>
        
        {/* <div className={CheckoutPageStyles.internationalShippingPrompt || ''}>
          <strong className={`title is-4 ${CheckoutPageStyles.promptTitle || ''}`}>Are you ordering within Ireland or internationally? </strong>
          <div>
            <div 
              className={`box ${CheckoutPageStyles.box || ''}`}
            >
              <div onClick={() => {
                navigate('/checkout-ireland/')
              }}>
                <img src="/media/xl-flag-ireland.svg" alt="Ireland" />
                <span>Ireland</span>
                <small>Delivery or Collection</small>
              </div>
            </div>
            <div className={`box ${CheckoutPageStyles.box || ''} ${isNotShippable && (CheckoutPageStyles.notShippable || '')}`}>
              <div onClick={() => {
                isNotShippable
                  ? throwPopupError('Your order contains products that cannot be shipped internationally.')
                  : navigate('/checkout-international/')
              }}>
                <img src="/media/xl-flag-international.svg" alt="International" />
                <span>International</span>
                <small>Tracked Shipping</small>
              </div>
              {isNotShippable && (
                <p className={`notification ${CheckoutPageStyles.notShippableNote || ''}`}>
                  <b>Note:</b> Your order contains products that cannot be shipped internationally. Click the button below to clear your basket and add only products that can be shipped.
                  <button 
                    className={`button is-secondary ${CheckoutPageStyles.clearBasket || ''}`}
                    onClick={() => {
                      destroy()
                      window.location.reload()
                    }}
                  >
                    Clear Basket
                  </button>
                </p>
              )}
            </div>
          </div>
        </div> */}

        {/* Container for popup error messages */}
        <ToastContainer />
      </PageTemplate>
    )
  }
  else {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
      >
        <div className="has-text-centered">
          <p className="has-text-center has-text-primary" style={{marginBottom: '32px'}}>
            <strong>Your cart is empty.</strong>
          </p>
          <Button url="/" text="Shop Now" class="is-secondary is-strong" />
        </div>
      </PageTemplate>
    )
  }
}

export default PaymentPage
